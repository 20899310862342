$(document).ready(function() {

    $(".fancybox").fancybox({
        prevEffect		: 'none',
        nextEffect		: 'none',
        closeBtn		: false,
        helpers		: {
            buttons	: {},
            media: true,
            title	: {
                type: 'inside'
            },
            overlay: {
                locked: false
            }
        }
    });
    $(window).resize(function() {
        var more = document.getElementById("js-navigation-more");
        if ($(more).length > 0) {
            var windowWidth = $(window).width();
            var moreLeftSideToPageLeftSide = $(more).offset().left;
            var moreLeftSideToPageRightSide = windowWidth - moreLeftSideToPageLeftSide;

            if (moreLeftSideToPageRightSide < 330) {
                $("#js-navigation-more .submenu .submenu").removeClass("fly-out-right");
                $("#js-navigation-more .submenu .submenu").addClass("fly-out-left");
            }

            if (moreLeftSideToPageRightSide > 330) {
                $("#js-navigation-more .submenu .submenu").removeClass("fly-out-left");
                $("#js-navigation-more .submenu .submenu").addClass("fly-out-right");
            }
        }
    });

    $(document).ready(function() {
        var menuToggle = $("#js-mobile-menu").unbind();
        $("#js-navigation-menu").removeClass("show");
        menuToggle.on("click", function(e) {
            e.preventDefault();
            $("#js-navigation-menu").slideToggle(function(){
                if($("#js-navigation-menu").is(":hidden")) {
                    $("#js-navigation-menu").removeAttr("style");
                }
            });
        });
        $("#js-navigation-menu").on('update.zf.magellan', function() {
            console.log('Magellan updated');
        });

        jQuery('.nav-link').bind('click', function(){
            $("#js-navigation-menu").slideToggle(function(){
                if($("#js-navigation-menu").is(":hidden")) {
                    $("#js-navigation-menu").removeAttr("style");
                }
            });
        })

    });
});